/** The Run Kinematics report tab */
import { IonCol, IonGrid, IonRow, IonText, IonCardContent, IonFabButton, IonIcon, IonCardTitle } from '@ionic/react';
import * as React from 'react';

// Styles
import styles from '../Styles/Reports.module.css';

import { FunctionalMovementKinematicsGraph } from '../components/Graphs';
import { useMemo, useState } from 'react';
import { SquatKinematicData } from '../../../model/reportV2.model';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { LegendToast } from '../../../components/LegendToast/LegendToast';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';
import { IconSize } from '../../../components/Icon/types';
import toggle from '../../../assets/icons/toggle-left.svg';
import { Icon } from '../../../components/Icon';
import { colorPalette, helpCircleOutline } from 'ionicons/icons';
import { Card } from '../../../components/Card';
import { KinematicsCardHeader } from '../../../components/CardHeader/KinematicsCardHeader';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { DataTableSection } from '../../../components/DataTable/DataTableSection';
import { DataTableRow } from '../../../components/DataTable/DataTableRow';
import { DataTableColumn } from '../../../components/DataTable/DataTableColumn';
import { DataTable } from '../../../components/DataTable/DataTable';
import { DataTableHeader } from '../../../components/DataTable/DataTableHeader';
import { mergeClassNames } from '../../../utilities/mergeClassNames';

type KinematicsReportProps = {
  tabData: SquatKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const SquatsKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showInfoToast, setShowInfoToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);

  const toggleShowAllStrides = () => {
    setShowAllStrides((prev) => {
      const newValue = !prev;
      localStorage.setItem('showAllStridesKinematics', newValue ? 'true' : 'false');
      return newValue;
    });
  };

  const colors = ['tableLabel_succes', 'tableLabel_warning', 'tableLabel_danger', 'tableLabel_succes'];
  function getColor(index: number) {
    return colors[index % colors.length];
  }

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  React.useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card style={{ height: 'fit-content', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <KinematicsCardHeader />
              <IonCardContent>
                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px', backgroundColor: 'transparent' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Pelvis</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Transversal.series}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Hip</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Knee</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Ankle</IonCardTitle>
                    </IonRow>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        {/* Tables */}
        <IonRow class='ion-no-padding ion-justify-content-center ion-padding-bottom'>
          <IonCol size='12'>
            <Card style={{ margin: 0 }}>
              <CardHeader title={'Range of Motion'} />
              <IonCardContent>
                <IonGrid className='ion-no-padding'>
                  <IonRow
                    className={mergeClassNames(styles.full_height, 'ion-padding-vertical', 'ion-align-items-start')}
                  >
                    <DataTable>
                      <DataTableHeader titles={['', 'Left', 'Right', 'Symmetry']} />

                      <DataTableSection>
                        <DataTableRow>
                          <DataTableColumn type='key'>Pelvis</DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[0][0].toFixed(1) + '°'} ± {tabData.romData[0][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[0][2].toFixed(1) + '°'} ± {tabData.romData[0][3].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tabData.romData[0][4].toFixed(0) + '%'}</DataTableColumn>
                        </DataTableRow>
                      </DataTableSection>
                      <DataTableSection>
                        <DataTableRow>
                          <DataTableColumn type='key'>Hip</DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[1][0].toFixed(1) + '°'} ± {tabData.romData[1][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[1][2].toFixed(1) + '°'} ± {tabData.romData[1][3].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tabData.romData[1][4].toFixed(0) + '%'}</DataTableColumn>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableColumn type='key' indent>
                            External rotation <IonIcon src={helpCircleOutline} id='trigger-hip-external-rotation' />
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[2][0])}>
                            {tabData.hipLabels.externalRotationLabel_Left}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[2][1])}>
                            {tabData.hipLabels.externalRotationLabel_Right}
                          </DataTableColumn>
                          <DataTableColumn />
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableColumn type='key' indent>
                            Internal rotation <IonIcon src={helpCircleOutline} id='trigger-hip-internal-rotation' />
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[3][0])}>
                            {tabData.hipLabels.internalRotationLabel_Left}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[3][1])}>
                            {tabData.hipLabels.internalRotationLabel_Right}
                          </DataTableColumn>
                          <DataTableColumn />
                        </DataTableRow>
                      </DataTableSection>

                      <DataTableSection>
                        <DataTableRow>
                          <DataTableColumn type='key'>Knee</DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[2][0].toFixed(1) + '°'} ± {tabData.romData[2][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[2][2].toFixed(1) + '°'} ± {tabData.romData[2][3].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tabData.romData[2][4].toFixed(0) + '%'}</DataTableColumn>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableColumn type='key' indent>
                            Varus / Valgus <IonIcon src={helpCircleOutline} id='trigger-knee-valgus' />
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[4][0])}>
                            {tabData.varusValgusLabel_Left}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[4][1])}>
                            {tabData.varusValgusLabel_Right}
                          </DataTableColumn>
                          <DataTableColumn />
                        </DataTableRow>
                      </DataTableSection>

                      <DataTableSection>
                        <DataTableRow>
                          <DataTableColumn type='key'>Ankle</DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[3][0].toFixed(1) + '°'} ± {tabData.romData[3][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>
                            {tabData.romData[3][2].toFixed(1) + '°'} ± {tabData.romData[3][3].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tabData.romData[3][4].toFixed(0) + '%'}</DataTableColumn>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableColumn type='key' indent>
                            Early heel raise
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[0][0])}>
                            {tabData.ankleLabels.earlyHeelRaiseLabel_Left}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={getColor(tabData.qualityData[0][1])}>
                            {tabData.ankleLabels.earlyHeelRaiseLabel_Right}
                          </DataTableColumn>
                          <DataTableColumn />
                        </DataTableRow>
                      </DataTableSection>
                    </DataTable>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        <FloatingButton
          slot='fixed'
          horizontal='end'
          vertical='bottom'
          side='top'
          buttonIcon='sliders'
          iconSize={IconSize.large}
        >
          <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Movements'}>
            <IonIcon icon={toggle}></IonIcon>
          </IonFabButton>
          <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
            <IonIcon icon={colorPalette}></IonIcon>
          </IonFabButton>
          <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
            <Icon name={'info'}></Icon>
          </IonFabButton>
        </FloatingButton>

        <LegendToast
          isOpen={showToast}
          onClose={handleCloseToast}
          duration={3000} // 3 seconds
        />

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the graph.'
        />
      </>
    );
  }
};
