/** The Run Kinematics report tab */
import { IonCol, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import * as React from 'react';

import { VariabilityGraphLegenda } from '../components/CustomGraphComponents';

import { useEffect, useState } from 'react';
import toggle from '../../../assets/icons/toggle-left.svg';
import { Card } from '../../../components/Card';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';
import { Icon } from '../../../components/Icon';
import { IconSize } from '../../../components/Icon/types';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { ReportModal } from '../../../components/Modal/ReportModal';
import { RunVariabilityData } from '../../../model/reportV2.model';
import { VariabilityOverviewGraph } from '../components/Graphs';
import { VariabilityModalContent } from '../components/Modals';
import styles from '../Styles/Reports.module.css';

type RunVariabilityReportTabProps = {
  tabData: RunVariabilityData;
  setFloatingButtons: (buttons: React.ReactNode) => void;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunVariabilityReportTab = ({ tabData, setFloatingButtons }: RunVariabilityReportTabProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [showInfoToast, setShowInfoToast] = useState(false);

  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });

  const seriesHip = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Pelvis : tabData.mean.Pelvis;
  }, [tabData, showAllStrides]);
  const seriesKnee = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Knee : tabData.mean.Knee;
  }, [tabData, showAllStrides]);
  const seriesAnkle = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Ankle : tabData.mean.Ankle;
  }, [tabData, showAllStrides]);

  const toggleShowAllStrides = () => {
    setShowAllStrides((prev) => {
      const newValue = !prev;
      localStorage.setItem('showAllStridesVariability', newValue ? 'true' : 'false');
      return newValue;
    });
  };

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  useEffect(() => {
    const buttons = (
      <>
        <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
          <IonIcon icon={toggle}></IonIcon>
        </IonFabButton>
        <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
          <Icon name={'info'}></Icon>
        </IonFabButton>
      </>
    );
    setFloatingButtons(buttons); // Update buttons in parent
    return () => setFloatingButtons(null); // Clean up on unmount
  }, [setFloatingButtons]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <ReportModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          setShowInfo={() => setShowMoreInfoModal({ show: false, joint: '' })}
        >
          <IonGrid className='' style={{ height: '100%' }}>
            {showMoreInfoModal.joint === 'Hip' && (
              <VariabilityModalContent
                crp_Left={tabData.modalData.Pelvis.Left.crpData}
                crp_Right={tabData.modalData.Pelvis.Right.crpData}
                coordinativeVariabilityData_Left={tabData.modalData.Pelvis.Left.coordinativeVariabilityData}
                coordinativeVariabilityData_Right={tabData.modalData.Pelvis.Right.coordinativeVariabilityData}
                setShowMoreInfoModal={setShowMoreInfoModal}
                annotations={true}
                graphAnnotations_Left={tabData.graphAnnotationsHip_Left}
                graphAnnotations_Right={tabData.graphAnnotationsHip_Right}
              />
            )}
            {showMoreInfoModal.joint === 'Knee' && (
              <VariabilityModalContent
                crp_Left={tabData.modalData.Knee.Left.crpData}
                crp_Right={tabData.modalData.Knee.Right.crpData}
                coordinativeVariabilityData_Left={tabData.modalData.Knee.Left.coordinativeVariabilityData}
                coordinativeVariabilityData_Right={tabData.modalData.Knee.Right.coordinativeVariabilityData}
                setShowMoreInfoModal={setShowMoreInfoModal}
                annotations={true}
                graphAnnotations_Left={tabData.graphAnnotations_Left}
                graphAnnotations_Right={tabData.graphAnnotations_Right}
              />
            )}
            {showMoreInfoModal.joint === 'Ankle' && (
              <VariabilityModalContent
                crp_Left={tabData.modalData.Ankle.Left.crpData}
                crp_Right={tabData.modalData.Ankle.Right.crpData}
                coordinativeVariabilityData_Left={tabData.modalData.Ankle.Left.coordinativeVariabilityData}
                coordinativeVariabilityData_Right={tabData.modalData.Ankle.Right.coordinativeVariabilityData}
                setShowMoreInfoModal={setShowMoreInfoModal}
                annotations={true}
                graphAnnotations_Left={tabData.graphAnnotations_Left}
                graphAnnotations_Right={tabData.graphAnnotations_Right}
              />
            )}
          </IonGrid>
        </ReportModal>

        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding '>
                      <p className={styles.variabilityTitle}>Hip</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                    <VariabilityGraphLegenda legend={tabData.pelvisLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesHip.Left.crpData}
                    coordinativeVariabilityData={seriesHip.Left.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotationsHip_Left}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesHip.Right.crpData}
                    coordinativeVariabilityData={seriesHip.Right.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotationsHip_Right}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>

        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding'>
                      <p className={styles.variabilityTitle}>Knee</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                    <VariabilityGraphLegenda legend={tabData.kneeLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesKnee.Left.crpData}
                    coordinativeVariabilityData={seriesKnee.Left.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotations_Left}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesKnee.Right.crpData}
                    coordinativeVariabilityData={seriesKnee.Right.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotations_Right}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>

        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding'>
                      <p className={styles.variabilityTitle}>Ankle</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                    <VariabilityGraphLegenda legend={tabData.ankleLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesAnkle.Left.crpData}
                    coordinativeVariabilityData={seriesAnkle.Left.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotations_Left}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                  <VariabilityOverviewGraph
                    crpData={seriesAnkle.Right.crpData}
                    coordinativeVariabilityData={seriesAnkle.Right.coordinativeVariabilityData}
                    graphAnnotations={tabData.graphAnnotations_Right}
                    strokeWidth={showAllStrides ? 1 : 2}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the card.'
        />
      </>
    );
  }
};
